import { useEffect, useState } from "react";
import { ImageGallery } from "../components";
import { ImageFromPath } from "../models";
import { importAllImages } from "../utils";

export const Clients = () => {
  const [images, setImages] = useState<ImageFromPath[]>([]);

  useEffect(() => {
    const getImages = importAllImages(
      require.context("../assets/clients", false, /\.(png|jpe?g|svg)$/)
    );
    setImages(getImages);
  }, []);

  return (
    <>
      <br />
      <div className="flex justify-center">
        <article className="prose md:prose-lg lg:prose-xl">
          <h2>CLIENTS</h2>
          <hr />
          <p>
            Our clients are continuously trusting us as their I.T products and
            I.T solutions provider. We treat our clients highly valuable and our
            clients are still counting until now. Would you like to be one of
            them? Contact Us
          </p>
        </article>
      </div>
      <div className="container mx-auto px-4">
        <ImageGallery images={images} />
      </div>
    </>
  );
};
