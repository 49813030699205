export const About = () => {
  return (
    <>
      <br />
      <div className="flex justify-center">
        <article className="prose md:prose-lg lg:prose-xl">
          <h2>ABOUT US</h2>
          <hr />
          <p>
            <span className="text-blue-600/100 font-medium">
              ADAPTIVE NETS, INC
            </span>{" "}
            is an IT Sales and Consulting services company with consultants in
            Philippines, United States and Canada. Our primary goal is the same
            as the one we began: to improve our clients businesses by marrying
            advanced technology with clear thinking and solid expertise. We
            offer a full range of sales and network/security consulting, web
            development, mobile application development, computer
            graphics/animation, systems integration and managed services
            tailored to the unique needs of each of the industries in which we
            have deep expertise. This unwavering commitment to our clients needs
            has allowed us to build long-term, trusting and loyal relationships
            with an enviable list of clients. It has given us the ability to set
            ambitious objectives for client projects and meet them time and time
            again with unparalleled speed and precision. We build what we
            promise: business solutions that work. Our Values are at the
            foundation of our business at Adaptive Nets, Inc.
          </p>
          <h3>OUR VALUES</h3>
          <h4>Teamwork</h4>
          <p>
            Winning through collaboration and respectful, trusting
            relationships.
          </p>
          <h4>Integrity</h4>
          <p>Honest, consistent and true to our word.</p>
          <h4>Initiative</h4>
          <p>Leading by example and working to “make it happen.”</p>
          <h4>Excellence</h4>
          <p>
            Striving to consistently give the best solutions using the most
            up-to-date knowledge and technology.
          </p>
        </article>
      </div>
    </>
  );
};
