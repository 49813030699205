import { Navbar } from "flowbite-react";
import AppNavLink from "../../components/Navigation/AppNavLink";
import logo from "../../assets/logo.jpg";

export const PageNavbar = () => {
  return (
    <>
      <Navbar fluid={true} rounded={true}>
        <Navbar.Brand href="/">
          <img src={logo} className="mr-3 h-6 sm:h-10" alt="Flowbite Logo" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <AppNavLink to="/" text="Home" />
          <AppNavLink to="/about" text="About Us" />
          <AppNavLink to="/clients" text="Clients" />
          <AppNavLink to="/certifications" text="Certifications" />
          <AppNavLink to="/contact" text="Contact" />
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};
