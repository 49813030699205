import { ImageFromPath } from "../../models";

interface ImageProps {
  images: ImageFromPath[];
  size?: number;
}

export const ImageGallery = ({ images, size }: ImageProps) => {
  let sizeClass = `grid gap-4 lg:grid-cols-4 mt-4`;
  if (size) {
    sizeClass = `grid gap-${size} lg:grid-cols-${size} mt-4`;
  }
  return (
    <section className={sizeClass}>
      {images.map((d: ImageFromPath) => (
        <div className="flex justify-center">
          <img
            alt="gallery"
            className="max-w-lg h-auto rounded-lg"
            src={d.path}
          />
        </div>
      ))}
    </section>
  );
};
