export const Contact = () => {
  return (
    <>
      <br />
      <div className="flex justify-center">
        <article className="prose md:prose-lg lg:prose-xl">
          <h2>CONTACT US</h2>
          <hr />
          <h5 className="font-semibold">Office Address</h5>
          <p>
            12F Unit 1206 The Trade & Financial Tower, 7th Ave. & 32nd ST. BGC.
            Taguig City, Philippines
          </p>
          <h5 className="font-semibold">Email Address</h5>
          <p>sales@adaptivenets.com</p>
          <h5 className="font-semibold">or Call Us</h5>
          <p>9355474, 9020988</p>
        </article>
      </div>
      <br />
      <div className="container mx-auto px-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d965.4508542328782!2d121.05005659487044!3d14.553231489630795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c8f1583d4331%3A0xdfb11d591d06e95b!2sTrade%20and%20Financial%20Tower%2C%20Lane%20Q%2C%20Taguig%2C%20Metro%20Manila!5e0!3m2!1sen!2sph!4v1661488821754!5m2!1sen!2sph"
          width="100%"
          style={{ border: 0 }}
          title="google map"
        ></iframe>
      </div>
    </>
  );
};
