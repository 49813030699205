import { Carousel } from "flowbite-react";

interface Props {
  images: string[];
}

export const ImageCarousel = ({ images }: Props) => {
  return (
    <>
      <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
        <Carousel>
          {images.map((d: string, index: number) => (
            <img src={d} alt="..." key={index} />
          ))}
        </Carousel>
      </div>
    </>
  );
};
