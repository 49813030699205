import { ImageFromPath } from "../models";

export function importAllImages(r: any) {
    let images: ImageFromPath[] = [];
    r.keys().map((item: any, index: number) => {
        const name = item.replace("./", "")
        const path = r(item)
        images = [...images, {
            name,
            path
        }]
    });
    return images;
}