import { Card } from "flowbite-react";

interface CardInformativeProps {
  title: string;
  content: string;
}

export const CardInformative = ({ title, content }: CardInformativeProps) => {
  return (
    <Card>
      <div className="grid place-items-center">
        <h5 className="text-2xl tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
        <p className="font-normal text-gray-500 mt-3">
          {content}
        </p>
      </div>
    </Card>
  );
};
