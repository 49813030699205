import { Button, Card } from "flowbite-react/lib/esm/components";
import { useState } from "react";
import { ImageCarousel, CardInformative } from "../components";
import { useNavigate } from "react-router-dom";

export const HomePage = () => {
  let navigate = useNavigate();
  const [bannerImages] = useState([
    "https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/4911166/pexels-photo-4911166.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/257736/pexels-photo-257736.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/60504/security-protection-anti-virus-software-60504.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  ]);

  const navToContact = () => navigate("/contact");

  return (
    <>
      <ImageCarousel images={bannerImages} />
      <br />
      <Card>
        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          WE ARE TAKING OUR CLIENTS TO A NEW HEIGHTS
        </h5>
        <p className="font-normal text-gray-700 text-gray-400">
          We provide the best and complete solution to your technology needs
          with technical expertise at the most affordable cost. We strive to
          deliver only cutting edge technology tailored to your needs.
        </p>
        <Button onClick={navToContact}>
          CONTACT US
          <svg
            className="ml-2 -mr-1 h-4 w-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Button>
      </Card>
      <br />
      <div className="grid place-items-center">
        <h5 className="font-semibold text-2xl tracking-tight text-gray-900 dark:text-white">
          SERVICES WE OFFER
        </h5>
      </div>
      <br />
      <div className="animate-in slide-in-from-bottom zoom-in-50 fade-in-50 delay-800 duration-1000">
        <div className="grid gap-3 lg:grid-cols-3">
          <CardInformative
            title={"CISCO"}
            content={
              "Cisco routers and high end Cisco switches to integrate with other networking vendors such as HP, Intel- we can do it all. We taylor it to your needs and we recommend only the solutions that work."
            }
          />
          <CardInformative
            title={"Internet Security Management"}
            content={
              "We can install/configure and manage your corporate firewalls and intrusions detection and prevention systems. We are not vendor specific. We already earn years of experience in Cisco, Juniper, Checkpoint and Fortinet."
            }
          />
          <CardInformative
            title={"Operating System Administration"}
            content={
              "Windows 2003/2008 Active Directory, HP Unix Administration, VPAR, NPAR, Sun Solaris, AIX, Linux - we've got them! We have a lot of experience in email integration, website infrastructure design and management."
            }
          />
        </div>
        <div className="grid gap-3 lg:grid-cols-3 mt-3">
          <CardInformative
            title={"Web Design and Web Development"}
            content={
              "Do you need a website for your start-ups or enterprise level company? Our team can provide a website design or web application that suits your needs."
            }
          />
          <CardInformative
            title={"Mobile App Development"}
            content={
              "We have a team of passionate programmers who can develop mobile apps running in Android, iOS and Windows Phone that depends on your needs."
            }
          />
          <CardInformative
            title={"Computer Effects and Graphics Design"}
            content={
              "We create logo/icon design, graphics design and motion effects design."
            }
          />
        </div>
      </div>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6">
          <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Built for business like yours
            </h2>
            <p className="mb-5 font-light text-gray-500 sm:text-xl text-gray-400">
              <span className="text-blue-600/100 font-medium">
                ADAPTIVE NETS INC.
              </span>{" "}
              provides professional solutions including hardware/software
              products, software licenses and IT services.
            </p>
          </div>
          <div className="animate-in fade-in zoom-in duration-1000 delay-300">
            <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
              <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                <h3 className="mb-4 text-2xl font-semibold">
                  Technical Support
                </h3>
                <p className="font-light text-gray-500 sm:text-lg text-gray-400">
                  We have a team of highly skilled individuals and the area of
                  Systems, Networking, Security, Cloud and Application
                  Development. We are a one stop shop for all your technical
                  needs.
                </p>
              </div>

              <div className="flex flex-col p-4 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                <h3 className="mb-4 text-2xl font-semibold">
                  Sales & Marketing
                </h3>
                <p className="font-light text-gray-500 sm:text-lg text-gray-400">
                  Our sales & marketing team are seasoned to provide you with
                  the best price and customer service in the industry.
                </p>
              </div>

              <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                <h3 className="mb-4 text-2xl font-semibold">I.T Services</h3>
                <p className="font-light text-gray-500 sm:text-lg text-gray-400">
                  We help a lot of companies across the nation and have global
                  reach. That's why we have local clients and overseas providing
                  IT support. Our services are excellent that is why our clients
                  are continuing to trust us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="px-4 animate-in fade-in zoom-in duration-1000 delay-800">
        <div className="flex flex-wrap -mx-4 -mb-8">
          <div className="md:w-1/4 px-4 mb-8">
            <img
              className="rounded shadow-md"
              src="https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2074&q=80"
              alt=""
            />
          </div>
          <div className="md:w-1/4 px-4 mb-8">
            <img
              className="rounded shadow-md"
              src="https://images.unsplash.com/photo-1580894894513-541e068a3e2b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt=""
            />
          </div>
          <div className="md:w-1/4 px-4 mb-8">
            <img
              className="rounded shadow-md"
              src="https://images.unsplash.com/photo-1508780709619-79562169bc64?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt=""
            />
          </div>
          <div className="md:w-1/4 px-4 mb-8">
            <img
              className="rounded shadow-md"
              src="https://images.unsplash.com/photo-1553775282-20af80779df7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
};
