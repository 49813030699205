import { useState, useEffect } from "react";
import { ImageGallery } from "../components";
import { ImageFromPath } from "../models";
import { importAllImages } from "../utils";

export const Certifications = () => {
  const [images, setImages] = useState<ImageFromPath[]>([]);

  useEffect(() => {
    const getImages = importAllImages(
      require.context("../assets/certification", false, /\.(png|jpe?g|svg)$/)
    );
    setImages(getImages);
  }, []);

  return (
    <>
      <br />
      <div className="flex justify-center">
        <article className="prose md:prose-lg lg:prose-xl">
          <h2>CERTIFICATIONS</h2>
          <hr />
        </article>
      </div>
      <div className="container mx-auto px-4">
        <ImageGallery images={images} />
      </div>
    </>
  );
};
