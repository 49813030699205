import { Outlet } from "react-router-dom";
import { PageNavbar } from "./Navbar/Navbar";
import { Footer } from "./Footer";

export const Layout = () => {
  return (
    <>
      <div className="h-screen">
        <div className="sticky top-0 z-50 shadow-sm shadow-1xl">
          <PageNavbar />
        </div>
        <div className="relative">
          <Outlet />
        </div>
        <div className="sticky top-[100vh]">
          <Footer />
        </div>
      </div>
    </>
  );
};
